/* eslint-disable import/prefer-default-export */
import Popup from 'reactjs-popup';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import type { Event } from '../../../types';
import { Button } from '../../atoms';
import style from '../../../features/calendar/Calendar.module.scss';
// import { setRenderPatientOverview } from '../../../app/appSlice';

const cx = classNames.bind(style);

type EventData = {
  date: string;
  fromTime: string;
  toTime: string;
  duration: string;
};

type CalendarPopupProps = {
  event: Event;
  closeModal: (patientId: number) => void;
  openPopup: { id: number; open: boolean };
  eventData: EventData;
};

export const CalendarPopup = ({
  event, closeModal, openPopup, eventData,
}: CalendarPopupProps) => {
  const PopupClasses = cx({ CalendarPopup: true });
  const NameClasses = cx({ CalendarPopupName: true });
  const RegularDetails = cx({ CalendarPopupRegularDetails: true });
  const BoldDetails = cx({ CalendarPopupBoldDetails: true });
  const TerminType = cx({ CalendarPopupTerminType: true });
  const PopupButton = cx({ CalendarPopupButton: true });

  const navigate = useNavigate();

  return (
    <Popup
      trigger={<div></div>}
      open={event.patientId === openPopup.id && openPopup.open}
      closeOnDocumentClick
      onClose={() => closeModal(event.patientId)}
      position={eventData.date.includes('Sonntag') ? 'left center' : 'right center'}
    >
      <div className={PopupClasses}>
        <div className={NameClasses}>{event.patientName}</div>
        <div className={BoldDetails}>{eventData.date}</div>
        <div className={RegularDetails}>
          {eventData.fromTime} bis {eventData.toTime} ({eventData.duration} Minuten)
        </div>
        <div className={TerminType}>Typ: {event.kindTermin}</div>
        <Button onClick={() => navigate(`/patients/Overview/${event.patientId}`)} className={PopupButton} type='primary'>
          Patient anzeigen
        </Button>
        {event.type === 'Online' ? <Button onClick={() => navigate(`/appointment/${event.appointmentId}`)} className={PopupButton} type='primary'>
          Zur Videosprechstunde
        </Button> : null}
      </div>
    </Popup>
  );
};
