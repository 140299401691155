/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import 'moment/locale/de';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import type {
  DoctorAppointments,
  DoctorAppointments as DoctorAppointmentsTypes,
  Patient as PatientType,
} from '../../../types';
import { CollapsibleCard, Loader } from '../../../components/atoms';
import {
  ClosedAppointmentHeader,
  NextAppointmentHeader,
  RequestHeader,
} from '../../../components/molecules';
import { ReadOnlyAppointmentForm } from '../../../components/molecules/AppointmentItems/ReadOnlyAppointmentForm';
import style from './Appointments.module.scss';
import { EditableAppointmentForm } from '../../../components/molecules/AppointmentItems/EditableAppointmentForm';
import { fetchFurAppointments, patientSliceSelector } from '../patientOverviewSlice';
import { ReadOnlyRequestForm } from '../../../components/molecules/AppointmentItems/ReadOnlyRequestForm';

type PropsTypes = {
  patientAppointments?: DoctorAppointmentsTypes[];
  patient?: PatientType;
  isMainOrSubDoctor?: boolean;
  setIsAppointmentUpdated: (value: boolean) => void;
  isAppointmentUpdated: boolean;
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
};

const cx = classNames.bind(style);
const pageClasses = cx({ Appointment: true });
const GreenHeading = cx('GreenHeading');

export function Appointments({
  patient,
  isMainOrSubDoctor,
  setIsAppointmentUpdated,
  isAppointmentUpdated,
  setCurrentPage,
}: PropsTypes): JSX.Element {
  const { furAppointments, loadPatient } = useSelector(patientSliceSelector);
  const dispatch = useDispatch();

  const getAppointmentType = (appointmentType: number | undefined) => {
    switch (appointmentType) {
      case 1:
        return 'Erstgespräch';
      case 2:
        return 'Wiedervorstellung';
      case 3:
        return 'Beratung';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (patient?.id) {
      const payload: any = { patientId: patient.id };
      dispatch(fetchFurAppointments(payload));
    }
  }, [patient, isAppointmentUpdated]);

  const renderNextAppointments = () => (
    furAppointments.next.map(
      (element: DoctorAppointmentsTypes, index: number) => {
        const appointmentType = getAppointmentType(
          element.appointmentType,
        );

        return (
          <CollapsibleCard key={index} isMainOrSubDoctor={isMainOrSubDoctor}>
            <CollapsibleCard.Header>
              <NextAppointmentHeader
                appointment={element}
                appointmentType={appointmentType}
                isAppointmentUpdated={isAppointmentUpdated}
              />
            </CollapsibleCard.Header>
            <CollapsibleCard.Body>
              <EditableAppointmentForm
                setIsAppointmentUpdated={setIsAppointmentUpdated}
                appointment={element}
              />
            </CollapsibleCard.Body>
          </CollapsibleCard>
        );
      },
    )
  );

  const renderOpenAppointments = () => (
    furAppointments.open.filter((element: DoctorAppointments) => !element.paymentMethod || element.paymentComplete === true).map(
      (element: any, index: number) => {
        if (element.appointmentType) {
          const appointmentType = getAppointmentType(
            element.appointmentType,
          );

          return (
          <CollapsibleCard key={index} isMainOrSubDoctor={isMainOrSubDoctor}>
            <CollapsibleCard.Header>
              <NextAppointmentHeader
                appointment={element}
                appointmentType={appointmentType}
                isAppointmentUpdated={isAppointmentUpdated}
              />
            </CollapsibleCard.Header>
            <CollapsibleCard.Body>
              <EditableAppointmentForm
                appointment={element}
                setIsAppointmentUpdated={setIsAppointmentUpdated}
              />
            </CollapsibleCard.Body>
          </CollapsibleCard>
          );
        }
        return (
          <CollapsibleCard>
            <CollapsibleCard.Header folgerezept={true} open={false}>
              <RequestHeader
                request={element}
                open={true}
                onClick={() => setCurrentPage('FollowRecipe')}
              />
            </CollapsibleCard.Header>
          </CollapsibleCard>
        );
      },
    )
  );

  const renderClosedAppointments = () => (
    furAppointments.closed.map((element: any, index: number) => {
      if (element.appointmentType) {
        const appointmentType = getAppointmentType(
          element.appointmentType,
        );

        return (
        <CollapsibleCard key={index} isMainOrSubDoctor={isMainOrSubDoctor}>
          <CollapsibleCard.Header>
            <ClosedAppointmentHeader
              appointment={element}
              appointmentType={appointmentType}
            />
          </CollapsibleCard.Header>
          <CollapsibleCard.Body>
            <ReadOnlyAppointmentForm appointment={element} />
          </CollapsibleCard.Body>
        </CollapsibleCard>
        );
      }
      return (
      <CollapsibleCard>
        <CollapsibleCard.Header folgerezept={true}>
          <RequestHeader request={element} />
        </CollapsibleCard.Header>
        <CollapsibleCard.Body>
            <ReadOnlyRequestForm request={element} />
          </CollapsibleCard.Body>
      </CollapsibleCard>
      );
    })
  );

  return (
    <div className={pageClasses}>
      {loadPatient ? <Loader />
        : <div className={style.MainContainer}>
          {(furAppointments.next && furAppointments.next.length !== 0) && (
            <div>
              <><h1 className={GreenHeading}>Nächste Termine</h1>
                {renderNextAppointments()}</>
            </div>
          )}
          {(furAppointments.open && furAppointments.open.length !== 0) && (
            <div>
              <><h1 className={GreenHeading}>Offene Termine</h1>
                {renderOpenAppointments()}</>
            </div>
          )}
          {(furAppointments.closed && furAppointments.closed.length !== 0) && (
            <div>
              <><h1 className={GreenHeading}>Abgeschlossene termine</h1>
                {renderClosedAppointments()}</>
            </div>
          )}
        </div>}
    </div>
  );
}
